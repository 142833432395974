@import "plugins/bootstrap/bootstrap";
//@import "../js/plugins/lightbox/lightbox.scss";

//$special_font:'Helvetica Neue LT Pro';
$special_font:'Helvetica Neue LT Pro';
$main_font:'HK Grotesk Legacy';
$black:#000000;
$white:#ffffff;

$font_color2:#717171;

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim{
    transition: 0.3s all ease;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin gradient_font{
    font-weight: 400;
   // font-family: $special_font;
    text-transform: uppercase;

    background: linear-gradient(#d79978, #a45e42);


    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .text-right{
        text-align: center;
    }
}

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6{
        font-weight: 400;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

.first_logo_col{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 16.66%;
    @media only screen and (max-width: 991px) {
        display: none;
    }
}

.logo_box_top{
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    background:$black;
    //height: 33.33%;
    height: 100%;
    //width: 16.66%;
    width: 16.58%;
    @include vertical;
    &.stop_fixed{
        position: absolute;
        top: auto;
        bottom: 0;
        width: 100%;
    }
    > div{
        width: 100%;
        text-align: center;
        //padding: 0 20px;
        padding: 55px 10px 0 10px;
    }
}

.main_gallery_carousel{
    position: relative;
    overflow: hidden;
    > ul{
        white-space: nowrap;
        position: relative;
        > li{
            display: inline-block;
            vertical-align: top;
            white-space: normal;
            width: 100%;
            background: $black;

            &:first-child{
                .main_gallery{
                    border-left: 10px solid $black;
                }

            }
        }
    }
    .main_gallery{
        height: 100vh;
        width: 100%;
        display: flex;
        border-top: 10px solid $black;
        @media only screen and (max-width: 991px) {
            height: 300px;
        }
        .main_gallery_col{
            width: 16.66%;
            height: 100%;
            position: relative;
        }
    }
    .image_holder{
        position: absolute;
        background: $black;
        left: 0;
    }
    .image{
        @include fill;
        height: calc(100% - 10px);
        width: calc(100% - 10px);
        top: 0px;
        left: 0px;
        @include bg_pos;
        @include anim;
        background-size: cover;
        z-index: 2;
        opacity: 0.7;
        &.logo_holder{
            cursor: pointer;
            @include vertical;
            > div{
                width: 100%;
                text-align: center;
            }
        }
        &:hover{
            opacity: 1;
        }
    }
    .height1{
        height: 16.66%;
    }
    .height2{
        height: 33.33%;
    }
    .height3{
        height: 49.99%;
    }
    .width1{
        width: 100%;
    }
    .width2{
        width: 200%;
    }
    .width3{
        width: 300%;
    }

    .offset_top_0{
        top: 0;
    }
    .offset_top_1{
        top: 16.66%;
    }
    .offset_top_2{
        top: 33.33%;
    }
    .offset_top_3{
        top: 49.99%;
    }
    .offset_top_4{
        top: 66.66%;
    }
    .offset_top_5{
        top: 83.33%;
    }
}

.new_main_gallery_carousel{
    position: relative;
    overflow: hidden;
    > ul{
        white-space: nowrap;
        position: relative;
        > li{
            display: inline-block;
            vertical-align: top;
            white-space: normal;
            width: 100%;
            background: $black;

            &:first-child{
                .main_gallery{
                    border-left: 10px solid $black;
                }

            }
            .main_gallery{
                height: 107.9vh;
                width: 100%;
                display: flex;
                border-top: 10px solid $black;
                @media only screen and (max-width: 991px) {
                    height: 300px;
                }
                .main_gallery_col{
                    width: 16.66%;
                    height: 100%;
                    position: relative;
                }
            }
            .image_holder{
                position: absolute;
                background: $black;
                left: 0;
            }
            .image{
                @include fill;
                height: calc(100% - 10px);
                width: calc(100% - 10px);
                top: 0px;
                left: 0px;
                @include bg_pos;
                @include anim;
                background-size: cover;
                z-index: 2;
                opacity: 0.7;
                &.logo_holder{
                    cursor: pointer;
                    @include vertical;
                    > div{
                        width: 100%;
                        text-align: center;
                    }
                }
                &:hover{
                    opacity: 1;
                }
            }
            .height1{
                height: 33.4%;
            }

            .height2{
                height: 23%;
            }

            .height3{
                height: 43.6%;
            }

            .height4{
                height: 45.4%;
            }

            .height5{
                //height: 27.2%;
                height:27.3%;
            }

            .height6{
                height:54.6%;
            }

            .height7{
                height:8.6%;
            }

            .height8{
                height: 23.4%;
            }

            .height9{
                height: 22%;
            }

            .offset_top_0{
                top: 0;
            }
            .offset_top_1{
                top: 33.4%;
            }
            .offset_top_2{
                top:56.4%;
            }
            .offset_top_3{
                top: 45.4%;
            }
            .offset_top_4{
                //top: 72.6%;
                top: 72.8%;
            }
            .offset_top_5{
                top: 68.4%;
            }

            .offset_top_6{
                top: 91.4%;
            }

            .offset_top_7{
                top: 23.4%;
            }

            .width1{
                width: 100%;
            }
            .width2{
                width: 200%;
            }
        }
    }
}

.default_font{
    font-size: 18px;
    line-height: 32px;
    color: #cecece;
    @media only screen and (max-width: 1500px) {
        font-size: 15px;
        line-height: 29px;
    }
    h1{
        font-size:72px;
        line-height: 90px;
        @media only screen and (max-width: 1600px) {
            font-size: 62px;
            line-height: 80px;
        }
        @media only screen and (max-width: 1500px) {
            font-size: 53px;
            line-height: 71px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 43px;
            line-height: 51px;
        }
    }
    h2{
        font-size:53px;
        line-height: 71px;
        @media only screen and (max-width: 1500px) {
            font-size:43px;
            line-height: 61px;
        }
        @media only screen and (max-width: 1400px) {
            //font-size: 39px;
            //line-height: 57px;
            font-size: 34px;
            line-height: 52px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 32px;
            line-height: 50px;
        }
    }
    h3{
        font-size: 30px;
        line-height: 48px;
        @media only screen and (max-width: 1200px) {
            font-size: 24px;
            line-height: 42px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 42px;
        @media only screen and (max-width: 1200px) {
            font-size: 21px;
            line-height: 35px;
        }
    }
    h5{
        font-size: 21px;
        line-height: 35px;
        @media only screen and (max-width: 1200px) {
            font-size: 18px;
            line-height: 31px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 31px;
        @media only screen and (max-width: 1200px) {
            font-size: 16px;
            line-height: 29px;
        }
    }
    //h1,h2,h3,h4,h5,h6{
    //    font-weight:300 !important;
    //}
    ul{
        list-style: disc;
        padding-left: 18px;
    }
    ol{
        padding-left: 18px;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.more_btn1{
    display: inline-block;
    //float: right;
    padding-right: 60px;
    background-repeat: no-repeat;
    background-position: right center;
    line-height: 41px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight:700;
    color:$white;
    background-image:url("./../img/more_arr_icon.svg");
    letter-spacing: 2px;
    @include anim;
    &:hover{
        color:$white;
        text-decoration: none;
        padding-right: 70px;
    }
}

.return_btn1{
    display: inline-block;
    padding-left: 60px;
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 41px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight:700;
    color:$white;
    background-image:url("./../img/return_arr_icon.svg");
    letter-spacing: 2px;
    @include anim;
    &:hover{
        color:$white;
        text-decoration: none;
        padding-left: 70px;
    }
}

.section_header{
    margin-bottom: 50px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
    }
    p{
        font-size: 14px;
        color: #cecece;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }
    div.default_font{
        h1,h2,h3,h4,h5,h6{
            @include gradient_font;
            letter-spacing: 1px;
        }
    }
}

.page{
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-image: url("./../img/BG.jpg");

    position: relative;

    overflow: hidden;
}

.page_header{
    //padding: 33px 0;
    padding: 33px 0 0 0;
    width: 100%;
    background: rgba(0,0,0,0.8);
    position: absolute;
    top: 0;
    letter-spacing: 0;
    z-index: 9999;
    &.start_fixed{
        position: fixed;
    }
    &.page_header2{
        position: fixed;
    }
    &.rwd_menu_open{
        padding: 15px 0;
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &-webkit-scrollbar-thumb {
            background: #a9694e;
        }

    }
    @media only screen and (max-width: 991px) {
        padding: 15px 0;
        .logo_box{
            position: relative;
            img{
                max-width: 100px;
                height: auto;
            }
            div.rwd_btn{
                width: 30px;
                position: absolute;
                top: 8px;
                right: 0;
                > div{
                    width: 100%;
                    height: 3px;
                    margin-bottom: 3px;
                    background: $white;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .navigation{
        text-align: right;
        padding-top: 23px;
        @media only screen and (max-width: 1400px) {
            padding-top: 17px;
        }
        @media only screen and (max-width: 1200px) {
            padding-top: 0;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 20px;
            text-align: left;
            display: none;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &.title_box, &.fast_contact_box{
                    margin-right: 117px;
                    @media only screen and (max-width: 1700px) {
                        margin-right: 80px;
                    }
                    @media only screen and (max-width: 1500px) {
                        margin-right: 50px;
                    }
                    @media only screen and (max-width: 1400px) {
                        margin-right: 30px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 50px;
                    }
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                }
                &.title_box{
                    .default_font{
                        line-height: 23px;
                        text-transform:uppercase;
                        font-family: $special_font;
                        p{
                            @include gradient_font;
                            font-weight:500;
                        }
                    }
                    @media only screen and (max-width: 1200px) {
                        display: none;
                    }
                }
                &.fast_contact_box{
                    a{
                        @include bg_pos;
                        width: 36px;
                        height: 36px;
                        display: inline-block;
                        margin-right: 10px;
                        background-size: 100% auto;
                        &:last-child{
                            margin-right: 0;
                        }
                        &.phone_link{
                            background-image: url("./../img/phone_icon_small.svg");
                        }
                        &.mail_link{
                            background-image: url("./../img/footer_contact_icon2.svg");
                        }
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
                &.menu_box{
                    //margin-right: 100px;
                    margin-right: 50px;
                    padding-top: 7px;
                    @media only screen and (max-width: 1600px) {
                        margin-right: 50px;
                    }
                    @media only screen and (max-width: 1400px) {
                        margin-right: 30px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 20px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                    }
                    nav.menu{
                        > ul{
                            > li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 79px;
                                position: relative;
                                > .rwd_menu_btn{
                                    position: absolute;
                                    width: 40px;
                                    height: 40px;
                                    top:0;
                                    right:0;
                                    text-align: center;
                                    @include anim;
                                    &.open_menu{
                                        transform: rotate(90deg);
                                    }
                                    &:after{
                                        content: '>';
                                        font-size: 18px;
                                        font-weight: 800;
                                        line-height: 40px;
                                        color: $white;
                                    }
                                }
                                @media only screen and (max-width: 1600px) {
                                    margin-right: 50px;
                                }
                                @media only screen and (max-width: 1400px) {
                                    margin-right: 30px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    margin-right: 20px;
                                }
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                &:hover{
                                   > div{
                                        a{
                                            &:after{
                                                left: 0;
                                                margin-left: -25px;
                                                width: calc(100% + 50px);
                                            }
                                        }
                                    }
                                }
                                > div{
                                    &.on{
                                        a{
                                            &:after{
                                                left: 0;
                                                margin-left: -25px;
                                                width: calc(100% + 50px);
                                            }
                                        }
                                    }
                                    a{
                                        font-size: 16px;
                                        line-height: 30px;
                                        color:#e2e2e2;
                                        font-weight: 300;
                                        text-transform: uppercase;
                                        position: relative;
                                        display: block;
                                        padding-bottom: 64px;
                                        font-family: $special_font;
                                        letter-spacing: 1px;
                                        @media only screen and (max-width: 1500px) {
                                            padding-bottom: 56px;
                                        }
                                        @media only screen and (max-width: 1400px) {
                                            padding-bottom: 51px;
                                        }
                                        @media only screen and (max-width: 1200px) {
                                            padding-bottom: 47px;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            padding-bottom: 10px;
                                            display: inline-block;
                                        }
                                        &:after{
                                            @include anim;
                                            content: '';
                                            width: 0%;
                                            left: 50%;
                                            position: absolute;
                                            bottom: 0;
                                            height: 1px;
                                            background: rgba(21,15,12,1);
                                            background: -moz-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(21,15,12,1)), color-stop(50%, rgba(199,142,111,1)), color-stop(100%, rgba(21,15,12,1)));
                                            background: -webkit-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                            background: -o-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                            background: -ms-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                            background: linear-gradient(to right, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#150f0c', endColorstr='#150f0c', GradientType=1 );
                                        }
                                        &:hover{
                                            text-decoration: none;
                                        }
                                    }
                                }
                                &:hover{
                                    > ul{
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                > ul{
                                    position: absolute;
                                    left: -25px;
                                    top: 100%;
                                    width: 300px;
                                    text-align: left;
                                    background: #000000;
                                    @include anim;
                                    opacity: 0;
                                    visibility: hidden;
                                    @media only screen and (max-width: 991px) {
                                        position: relative;
                                        left: 0;
                                        width: 100%;
                                        top: 0;
                                        opacity: 1;
                                        visibility: visible;
                                        background: transparent;
                                        padding-top: 15px;
                                        display: none;
                                    }
                                    > li{
                                        @media only screen and (max-width: 991px) {
                                            margin-bottom: 15px;
                                            &:last-child{
                                                margin-bottom: 0;
                                            }
                                        }
                                        &:last-child{
                                           a{
                                               border-bottom:none;
                                           }
                                        }
                                        a{
                                            font-size: 16px;
                                            line-height: 30px;
                                            color:#e2e2e2;
                                            font-weight: 300;
                                            text-transform: uppercase;
                                            position: relative;
                                            display: block;
                                            padding-bottom: 64px;
                                            font-family: $special_font;
                                            letter-spacing: 1px;
                                            &:hover{
                                                text-decoration: none;
                                                &:after{
                                                    left: 0;
                                                    margin-left: -25px;
                                                    width: calc(100% + 50px);
                                                }
                                            }
                                            @media only screen and (max-width: 1500px) {
                                                padding-bottom: 56px;
                                            }
                                            @media only screen and (max-width: 1400px) {
                                                padding-bottom: 51px;
                                            }
                                            @media only screen and (max-width: 1200px) {
                                                padding-bottom: 47px;
                                            }
                                            @media only screen and (max-width: 991px) {
                                                padding-bottom: 10px;
                                                display: inline-block;
                                            }
                                            &:after{
                                                @include anim;
                                                content: '';
                                                width: 0%;
                                                left: 50%;
                                                position: absolute;
                                                bottom: 0;
                                                height: 1px;
                                                background: rgba(21,15,12,1);
                                                background: -moz-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                                background: -webkit-gradient(left top, right top, color-stop(0%, rgba(21,15,12,1)), color-stop(50%, rgba(199,142,111,1)), color-stop(100%, rgba(21,15,12,1)));
                                                background: -webkit-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                                background: -o-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                                background: -ms-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                                background: linear-gradient(to right, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#150f0c', endColorstr='#150f0c', GradientType=1 );
                                            }
                                            @media only screen and (min-width: 991px) {
                                                @include anim;
                                                width: 100%;
                                                color: $white;
                                                font-family: $special_font;
                                                letter-spacing: 1px;
                                                color:#e2e2e2;
                                                display: inline-block;
                                                padding: 15px;
                                                border-bottom: 1px solid rgba(255,255,255,0.2);
                                                font-weight: 300;
                                                &:hover{
                                                    text-decoration: none;
                                                    background: #a9694e;
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.social_box{
                    padding-top: 7px;
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 15px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                &.langs_box{
                    padding-top: 7px;
                    padding-left: 50px;
                    @media only screen and (max-width: 1400px) {
                        padding-left: 30px;
                    }
                    @media only screen and (max-width: 1200px) {
                        padding-left: 20px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        padding-top: 20px;
                    }
                    a{
                        display: inline-block;
                        margin-right: 10px;
                       // opacity: 0.3;
                        @include anim;
                        //&:hover{
                        //    opacity: 0.6;
                        //}
                        //&.on{
                        //    opacity: 1;
                        //}
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .dropdown{
        border-radius: 5px;
        position: relative;
        &:after{
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            background: rgb(215,153,120);
            background: -moz-linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
            background: -webkit-linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
            background: linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d79978",endColorstr="#a45e42",GradientType=1);
            content: '';
            position: absolute;
            z-index: -1;
            border-radius: 5px;
        }
        &.open{
            background-color: $black;
            .btn{
                background-color: $black;
            }
            .dropdown-menu{
                top: 100%;
                opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }
    .btn{
        padding: 0;
        width: auto;
        height: auto;
        border: none;
        background: $black;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        z-index: 1111;
        font-size: 15px;
        font-family: $main_font;
        width: 46px;
        background-repeat: no-repeat;
        background-position: left top 3px ;
        background-image: url("./../img/langs_icon.svg");

        padding: 5px 0 5px 0px;
        .lang_holder{
            @include gradient_font;
        }
        &:focus{
            outline: none;
        }
        .caret{
            display: none;
        }
    }
    .dropdown-menu{
        top: 0%;
        min-width: 0;
        text-align: center;
        border: none;
        display: block;
        visibility: hidden;
        opacity: 0;
        @include anim;
        width: 100%;
        z-index: 2;
        padding: 0;
        &:after{
            top: 0px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            background: rgb(215,153,120);
            background: -moz-linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
            background: -webkit-linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
            background: linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d79978",endColorstr="#a45e42",GradientType=1);
            content: '';
            position: absolute;
            z-index: -1;
        }
        > li{

            width: 100%;
            background: $black;
            &:hover{
                color: $white;
                background: rgb(215,153,120);
                background: -moz-linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                background: -webkit-linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                background: linear-gradient(230deg, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d79978",endColorstr="#a45e42",GradientType=1);
                a{
                    color: $white !important;
                    background: transparent;
                    background-clip: unset;
                    -webkit-text-fill-color: #ffffff;
                }
            }
            > a{
                padding: 0;
                font-size: 15px;
                @include anim;
                position: relative;
                @include gradient_font;
                padding: 5px 0 5px 0;
                width: 100%;

            }
        }
    }

}

.short_about{
    padding-top: 270px;
    @media only screen and (max-width: 1500px) {
        padding-top: 190px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 85px;
    }
    &.about{
        padding-top: 110px;
        margin-bottom: 130px;
        @media only screen and (max-width: 1500px) {
            padding-top: 60px;
            margin-bottom: 65px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 85px;
            margin-bottom: 30px;
        }
    }
    .section_header{
        .default_font{
            //padding-left: 14%;
            //@media only screen and (max-width: 1700px) {
            //    padding-left: 10%;
            //}
            //@media only screen and (max-width: 1600px) {
            //    padding-left: 5%;
            //}
            //@media only screen and (max-width: 1500px) {
            //    padding-left: 0;
            //}
            //@media only screen and (max-width: 991px) {
            //    text-align: center;
            //}
        }
    }
    .container{
        &.container2{
            > div.row{
                display: flex;
                margin-top: -135px;
                @media only screen and (max-width: 991px) {
                    display: block;
                    margin-top: 0;
                }
                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }
    .desc{
        &.top_desc{
            padding-left: 14%;
            padding-bottom: 30px;
            @media only screen and (max-width: 1700px) {
                padding-left: 10%;
            }
            @media only screen and (max-width: 1600px) {
                padding-left: 5%;
            }
            @media only screen and (max-width: 1500px) {
                padding-left: 0;
            }
            @media only screen and (max-width: 991px) {
                padding-bottom: 20px;
                text-align: center;
            }
        }
        &.bottom_desc{
            padding-left: 10px;
            margin-top: 10px;
            height: calc(100% - 10px);
            @media only screen and (max-width: 1200px) {
                margin-top: 10px;
                height: calc(100% - 10px);
            }
            @media only screen and (max-width: 991px) {
                height: auto;
                margin-top: 0;
                padding-left: 0;
                text-align: center;
                padding-top: 30px;
            }
            @include vertical;
            > div{
                width: 100%;
                padding-left: 10px;
                @media only screen and (max-width: 1200px) {
                    padding-left: 0;
                }
                .default_font{

                }
                .more_btn1{
                    margin-top: 90px;
                    @media only screen and (max-width: 1500px) {
                        margin-top: 50px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    .image_holder{
        &.top_image_holder{
            padding-left: 43px;
            @media only screen and (max-width: 1200px) {
                padding-left: 0;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
            .image{
                width: 100%;
                height: 0;
                padding-bottom: 69.3%;
                @include bg_pos;
                background-size: cover;
            }
        }
        &.bottom_image_holder{
            padding-right: 59px;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
            }
            .image{
                width: 100%;
                height: 0;
                padding-bottom: 103.3%;
                @include bg_pos;
                background-size: cover;
            }
        }
    }
}

.fast_contact{
    margin:120px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./../img/fog.png");
    background-size: auto 100%;
    @media only screen and (max-width: 1500px) {
        margin: 70px 0;
    }
    @media only screen and (max-width: 1200px) {
        margin: 40px 0;
    }
    @media only screen and (max-width: 991px) {
        margin: 30px 0;
    }
    &.fast_contact2{
        margin: 0px 0 0 0;
        > div{
            &.fast_contact_right{
                border-bottom: none;
            }
        }
    }
    > div{
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 93px 0;
        @media only screen and (max-width: 1500px) {
            padding: 53px 0;
        }
        @media only screen and (max-width: 991px) {
            padding: 0;
            width: 100%;
        }
        &.fast_contact_left{
            text-align: right;
            padding-right: 40px;
            border-top: 1px solid rgba(255,255,255,0.1);
            @media only screen and (max-width: 991px) {
                text-align: center;
                padding-right: 0;
                padding: 30px 0 0px 0;
            }
            a{
                padding-right: 130px;
                background-position: right center;
                background-image: url("./../img/large_contact_icon1.svg");
                @media only screen and (max-width: 991px) {
                    background-position: center top;
                    padding-right: 0;
                    padding-top: 50px;
                }
            }
        }
        &.fast_contact_right{
            text-align: left;
            padding-left: 40px;
            border-bottom: 1px solid rgba(255,255,255,0.1);
            @media only screen and (max-width: 991px) {
                text-align: center;
                padding-left: 0;
                padding: 0px 0 0 0;
            }
            a{
                padding-left: 130px;
                background-position: left center;
                background-image: url("./../img/large_contact_icon2.svg");
                @media only screen and (max-width: 991px) {
                    background-position: center top;
                    padding-top: 50px;
                    padding-left: 0;
                }
            }
        }
        @media only screen and (max-width: 991px) {
            .default_font{
                h3{
                    font-size: 18px;
                }
            }
        }
        a{
            padding: 18px 0px 14px 0;
            background-repeat: no-repeat;
            color: $white;
            font-family: $special_font;
            letter-spacing: 7px;
            color: $white;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 100;
            @include anim;
            @media only screen and (max-width: 991px) {
                background-size: 35px;
            }
            &:hover{
                //letter-spacing: 14px;
                text-decoration: none;
            }
        }
    }
}

.offer{
    margin-bottom: 140px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 70px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    .offer_info{
        margin-bottom: 87px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 1200px) {
            margin-bottom: 30px;
        }
        .section_header{
            margin-bottom: 0;
        }
        > .info{
            display: table;
            width: 100%;
            margin: 0;
            @media only screen and (max-width: 1200px) {
                display: block;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            > div{
                display: table-cell;
                vertical-align: top;
                float:none;
                padding: 0;
                @media only screen and (max-width: 1200px) {
                    display: inline-block;
                    vertical-align: top;
                }
                @media only screen and (max-width: 991px) {
                    display: block;
                }
                &.text-right{
                    vertical-align: middle;
                }
            }
        }
        .desc{
            @media only screen and (max-width: 1500px) {
                padding-left: 20px;
            }
            @media only screen and (max-width: 1200px) {
                padding-left: 0;
            }
            @media only screen and (max-width: 991px) {
                > .default_font{
                    display: none;
                }
            }
            .adres{
                margin-bottom: 30px;
                padding-left: 26px;
                background-repeat: no-repeat;
                background-position: left top;
                background-image:url('./../img/location.svg');
                color:#cecece;
                text-transform: uppercase;
                font-size: 13px;
                line-height: 23px;
                @media only screen and (max-width: 991px) {
                    display: inline-block;
                    background-position: left top 10px;
                    margin-bottom: 20px;
                    padding-top: 10px;
                }
            }
        }
    }
    .carousel_content{
        overflow: hidden;
        position: relative;
        > ul{
            position: relative;
            white-space: nowrap;
            > li{
                white-space: normal;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                margin-right: 10%;
                .offer_box{
                    display: flex;
                    @media only screen and (max-width: 991px) {
                        display: block;
                    }
                    div.offer_box_left{
                        //width: calc(100% - 430px);
                        width: calc(100% - 29%);
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        display: block;
                    }
                    div.offer_box_right{
                        //width: 430px;
                        padding-left: 30px;
                        width: 29%;
                        @media only screen and (max-width: 991px) {
                            padding-top: 20px;
                            width: calc(100% + 20px);
                            padding-left: 0;
                            margin: 0 -10px;
                            > div{
                                display: inline-block;
                                vertical-align: top;
                                padding: 0 10px;
                            }
                        }
                    }
                    .image{
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        z-index: 2;
                        &:hover{
                            .img{
                                transform: scale(1.2);
                            }
                        }
                        .img{
                            @include anim;
                            @include fill;
                            @include bg_pos;
                            background-size: cover;
                        }
                        &.large{
                            //height: 760px;
                            height: 39.6vw;
                            @media only screen and (max-width: 991px) {
                                height: 0;
                                padding-bottom: 69%;
                            }
                        }
                        &.small{
                            height: calc((100% - 30px * 2) / 3);
                            margin-bottom: 30px;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 0;
                                width: 33.33%;
                                .img{
                                    position: relative;
                                    width: 100%;
                                    height: 0;
                                    padding-bottom: 58%;
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        .carousel_nav{
            position: absolute;
            top: 0;
            left: 0;
            //width: calc(100% - 430px);
            width: calc(100% - 29%);
            text-align: right;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    height: 58px;
                    width: 114px;
                    background-color: $black;
                    @include anim;
                    @include bg_pos;
                    cursor: pointer;
                    position: relative;
                    z-index: 3;
                    @media only screen and (max-width: 991px) {
                        width: 50px;
                        background-size: 20px;
                        height: 30px;
                    }
                    &.arr_left{
                        background-image:url("./../img/arr_left.svg");
                    }
                    &.arr_right{
                        background-image:url("./../img/arr_right.svg");
                    }
                }
            }
        }
    }
}

.relations{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 0;
    }
    .steps{
        padding-top: 110px;
        @media only screen and (max-width: 1500px) {
            padding-top: 50px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 30px;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 50%;
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                    &:nth-child(odd){
                        .step_box{
                            > div{
                                &.desc{
                                    order: 1;
                                }
                                &.image_holder{
                                    order: 2;
                                }
                            }
                        }
                    }
                    &:nth-child(even){
                        .step_box{
                            > div{
                                &.desc{
                                    order: 2;
                                }
                                &.image_holder{
                                    order: 1;
                                }
                            }
                        }
                    }
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .step_box{
                    height: 300px;
                    width: 100%;
                    display: flex;
                    @media only screen and (max-width: 1200px) {
                        flex-flow:row;
                    }
                    @media only screen and (max-width: 991px) {
                        display: block;
                        height: auto;
                    }
                    > div{
                        width: 50%;
                        @media only screen and (max-width: 1200px) {
                            align-items: stretch !important;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        &.desc{
                            @include vertical;
                            border: 1px solid rgba(108,121,117,0.4);
                            position: relative;
                            @media only screen and (max-width: 1200px) {
                                display: block;
                            }
                            @media only screen and (max-width: 991px) {
                                padding: 10px 0 5px 0;
                            }
                            &:hover{
                                .hidden_desc{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            > div{
                                padding: 0 60px;
                                width: 100%;
                                @media only screen and (max-width: 1500px) {
                                    padding: 0 30px;
                                }
                            }
                            .number{
                                margin-bottom: 30px;
                                @media only screen and (max-width: 991px) {
                                    margin-bottom: 0;
                                }
                                p{
                                    font-weight: 300;
                                }
                                font-size: 60px;
                                color: rgba(108,121,117,0.4);
                            }
                            .title{
                                font-size: 21px;
                                line-height: 35px;
                                font-family: $special_font;
                                @media only screen and (max-width: 1500px) {
                                    font-size: 16px;
                                    line-height: 30px;
                                }
                                p{
                                    letter-spacing: 4px;
                                    @include gradient_font;
                                }
                            }
                            .hidden_desc{
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(1.02);
                                border-radius: 10px;
                                z-index: 2;
                                @include anim;
                                @include fill;
                                @include bg_pos;
                                background-size: cover;
                                background-image: url("./../img/hover_bg.jpg");
                                @media only screen and (max-width: 991px) {
                                    position: relative;
                                    background: transparent;
                                    opacity: 1;
                                    visibility: visible;
                                    transform: scale(1);
                                }
                                .hidden_desc_holder{


                                    @include fill;
                                    @include vertical;
                                    @media only screen and (max-width: 991px) {
                                        display: block;
                                        width: 100%;
                                        position: relative;
                                    }
                                    > div{
                                        width: 100%;
                                        padding: 0 60px;
                                        color: $white;
                                        font-size: 14px;
                                        line-height: 27px;
                                        @media only screen and (max-width: 1500px) {
                                            padding: 0 30px;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            padding:20px 0;
                                        }
                                        .title{
                                            margin-bottom: 20px;
                                            @media only screen and (max-width: 1500px) {
                                                margin-bottom: 0;
                                            }
                                            @media only screen and (max-width: 991px) {
                                                margin-bottom: 20px;
                                            }
                                            p{
                                                -webkit-text-fill-color:$white;
                                                background: none;
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.image_holder{
                            &.image_holder1{
                                .image{
                                    background-position: left bottom;
                                }
                            }
                            &.image_holder2{
                                padding-right: 120px;
                                @media only screen and (max-width: 1200px) {
                                    padding-right: 0;
                                }
                                .image{
                                    background-position: left bottom;
                                }
                            }
                            &.image_holder3{
                                padding-left: 35px;
                                @media only screen and (max-width: 1200px) {
                                    padding-left: 0;
                                }
                                .image{
                                    background-position: left top;
                                }
                            }
                            &.image_holder4{
                                .image{
                                    background-position: left top;
                                }
                            }
                        }
                        .image{
                            height: 100%;
                            width: 100%;
                            background-repeat: no-repeat;
                            background-size: 100% auto;
                            @media only screen and (max-width: 1200px) {
                                background-size: cover;
                            }
                        }
                    }
                }
            }
        }
    }
}

.more{
    padding: 75px 0;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
}

.page_footer{
    .footer_top{
        border-top: 1px solid rgba(255,255,255,0.1);
        padding: 138px 0;
        @media only screen and (max-width: 1500px) {
            padding: 69px 0;
        }
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
            .container{
                > div.row{
                    > div{
                        margin-bottom: 30px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .default_font{
            margin-bottom: 25px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 10px;
            }
            p{
                @include gradient_font;
            }
        }
        nav.footer_menu{
            ul{
                li{
                    a{
                        font-size: 14px;
                        line-height: 33px;
                        @include anim;
                        color:#cecece;
                        &:hover{
                            text-decoration: none;
                            color: $white;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
        .footer_contact{
            ul{
                li{
                    padding-left: 55px;
                    min-height: 37px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.adres{
                        background-image: url("./../img/footer_contact_icon1.svg");
                    }
                    &.phone{
                        background-image: url("./../img/phone_icon_small.svg");
                        padding-top: 8px;
                    }
                    &.mail{
                        background-image: url("./../img/footer_contact_icon2.svg");
                        padding-top: 8px;
                    }
                    &:hover{
                        p,a{
                            color:$white;
                        }
                    }
                    p,a{
                        color:#cecece;
                        font-size:14px;
                        line-height: 17px;
                        @include anim;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .footer_bottom{
        border-top: 1px solid rgba(255,255,255,0.1);
        min-height: 143px;
        display: flex;
        @media only screen and (max-width: 991px) {
            min-height: 0;
            display: block;
        }
        .footer_desc{
            span{
                font-size: 13px;
                display: block;
                margin-bottom: 5px;
            }
            font-size: 10px;
            line-height: 14px;
        }
        > div{
            &.footer_left{
                width: 53%;
                @include vertical;
                color:#a5a5a5;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding: 20px 0;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: middle;
                        &:first-child{
                            width: 165px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                margin-bottom: 20px;
                            }
                        }
                        &:last-child{
                            width: calc(100% - 165px);
                            margin-right: 0;
                            padding-left: 75px;
                            padding-right: 35px;
                            @media only screen and (max-width: 1400px) {
                                padding-left: 50px;
                            }
                            @media only screen and (max-width: 1200px) {
                                padding-left: 35px;
                                padding-right: 35px;
                            }
                            @media only screen and (max-width: 991px) {
                                padding-left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            &.footer_right{
                width: 47%;
                background: #0a1b16;
                @include vertical;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding: 20px 0;
                }
                > div{
                    width: 100%;
                    text-align: right;
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 30%;
                            @media only screen and (max-width: 1200px) {
                                margin-right: 15%;
                            }
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                width: 100%
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            .to_up{
                                letter-spacing: 2px;
                                font-weight: 700;
                                color:#a5a5a5;
                                font-size:13px;
                                line-height: 13px;
                                text-transform:uppercase;
                                cursor: pointer;
                                padding-top: 23px;
                                display: inline-block;
                                background-repeat: no-repeat;
                                background-position: left top;
                                background-image: url("./../img/to_up_arr.svg");
                                @media only screen and (max-width: 991px) {
                                    background-position: center top;
                                    margin-bottom: 10px;
                                }
                            }
                            .devs{
                                color:#a5a5a5;
                                font-size:13px;
                                > ul{
                                    > li{
                                        display: inline-block;
                                        img{
                                            position: relative;
                                            top: -2px;
                                            margin-left: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.top{
    width: 100%;
    padding-top: 140px;
    @media only screen and (max-width: 1600px) {
        padding-top: 137px;
    }
    @media only screen and (max-width: 1500px) {
        padding-top: 132px;
    }
    @media only screen and (max-width: 1400px) {
        padding-top: 121px;
    }
    @media only screen and (max-width: 1200px) {
        padding-top: 110px;
    }
    @media only screen and (max-width: 991px) {
        display: none;
    }
    .nav_way{
        display: inline-block;
        vertical-align: top;
        padding-top: 15px;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(255,255,255,0.15);
        > ul{
            > li{
                display: inline-block;
                vertical-align: middle;
                &:after{
                    content: '';
                    width: 18px;
                    height: 1px;
                    background: rgba(255,255,255,0.15);
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 17px 5px 17px;
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                    a{
                        text-decoration: underline;
                    }
                }
                a{
                    letter-spacing: 1px;
                    font-family: $special_font;
                    font-weight: 100;
                    text-transform: uppercase;
                    @include anim;
                    font-size: 16px;
                    line-height: 16px;
                    display:inline-block;
                    color:rgba(255,255,255,0.15);
                    &:hover{
                        color:rgba(255,255,255, 1);
                    }
                }
            }
        }
    }
}

.news{
    padding-top: 25px;
    @media only screen and (max-width: 991px) {
        padding-top: 85px;
    }
    .section_header{
        padding: 66px 0;
        @media only screen and (max-width: 1500px) {
            padding: 33px 0;
        }
        @media only screen and (max-width: 991px) {
            padding: 0;
        }
    }
    .news_list{
        margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 0px;
        }
        > ul{
            margin: 0 -62.5px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -30px;
            }
            > li{
                display: inline-block;
                vertical-align: top;
                //width: 50%;
                width: 100%;
                padding: 0 62.5px;
                margin-bottom: 50px;

                article{
                    padding-bottom: 50px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    //> div{
                    //    display: inline-block;
                    //    vertical-align: top;
                    //}
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    @media only screen and (max-width: 767px) {
                        flex-flow: column;
                        align-items: stretch;
                    }
                }
                @media only screen and (max-width: 1500px) {
                    padding: 0 30px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &:hover{
                    a{
                        text-decoration: none;
                    }
                    div.image{
                        .img{
                            opacity: 1;
                        }
                    }
                    div.desc_holder{
                        p{
                            color: $white;
                        }
                    }
                }
                &:nth-child(even){
                    //margin-top: -253px;
                    //@media only screen and (max-width: 1500px) {
                    //    margin-top: -177px;
                    //}
                    //@media only screen and (max-width: 991px) {
                    //    margin-top: 0;
                    //}
                    article{
                        > div{
                            &.image{
                                order: 2;
                                @media only screen and (max-width: 767px) {
                                    order: 1;
                                }
                            }
                            &.desc_holder{
                                order: 1;
                                @media only screen and (max-width: 767px) {
                                    order: 2;
                                }
                            }
                        }
                    }
                }
                div.image{
                    //width: 100%;
                    width: 37%;
                    height: 0;
                    //padding-bottom: 58.5%;
                    //padding-bottom: 30%;
                    padding-bottom: 22.516%;
                    background: $black;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-bottom: 35%;
                    }
                    @media only screen and (max-width: 767px) {
                        padding-bottom: 66%;
                    }
                    //.date{
                    //    position: absolute;
                    //    left: 60px;
                    //    bottom: 50px;
                    //    z-index: 2;
                    //    font-size: 18px;
                    //    line-height: 18px;
                    //    letter-spacing: 2px;
                    //    color: $white;
                    //    @media only screen and (max-width: 991px) {
                    //        bottom: 20px;
                    //        left: 20px;
                    //    }
                    //    p{
                    //        font-weight: 100;
                    //    }
                    //}
                    .img{
                        @include fill;
                        @include bg_pos;
                        @include anim;
                        background-size: cover;
                        opacity: 0.7;
                    }
                }
                .desc_holder{
                    padding: 50px 60px;
                    color: $white;
                    color: #cecece;
                    font-size: 18px;
                    line-height: 32px;
                    width: 63%;
                    @media only screen and (max-width: 1400px) {
                        font-size: 15px;
                        line-height: 29px;
                        padding: 20px 60px;
                    }
                    @media only screen and (max-width: 1200px) {
                        padding: 0 30px !important;
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 30px !important;
                    }
                    .more_btn1{
                        margin-top: 30px;
                    }
                    .date{
                        z-index: 2;
                        font-size: 18px;
                        line-height: 18px;
                        letter-spacing: 2px;
                        color: $white;
                        margin-bottom: 15px;
                        @media only screen and (max-width: 991px) {
                            bottom: 20px;
                            left: 20px;
                        }
                        p{
                            font-weight: 100;
                        }
                    }
                    @media only screen and (max-width: 1200px) {
                        padding: 30px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 30px 0;
                        width: 100%;
                    }
                    p{
                        @include anim;
                    }
                    header{
                        text-transform: uppercase;
                        color: $white;
                        margin-bottom: 30px;
                        @media only screen and (max-width: 1400px) {
                            margin-bottom: 15px;
                        }
                        h3{
                            letter-spacing:2px;
                            font-size: 30px;
                            line-height: 40px;
                            font-family: $special_font;
                            font-weight: 100;
                        }
                    }
                }
            }
        }
    }
}

.news_details{
    padding-top: 25px;
    .details_top{
        margin-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 35px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 85px;
        }
        > div.row{
            margin: 0 -30.5px;
            > div{
                float: none;
                display: inline-block;
                vertical-align: middle;
                padding: 0 30.5px;
                @media only screen and (max-width: 991px) {

                    display: block;
                }
                .date{
                    font-size:18px;
                    line-height: 18px;
                    color: $font_color2;
                    font-family: $special_font;
                    color: #717171;
                    p{
                        letter-spacing: 2px;
                        font-weight: 100;
                    }
                }
                .image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 63.1%;
                    background: $black;
                    position: relative;
                    .img{
                        @include fill;
                        @include bg_pos;
                        @include anim;
                        background-size: cover;
                        //opacity: 0.7;
                    }
                }
            }
        }
    }
    .middle_desc{
        text-align: justify;
        text-align-last: center;
        color: #cecece;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 35px;
            font-size: 16px;
            line-height: 41px;
        }
        .pd{
            padding: 0 30px;
            @media only screen and (max-width: 1500px) {
                padding: 0;
            }
        }
        p{
            font-weight:300;
        }
    }
    .middle_desc_collumn{
        margin-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 35px;
        }
        .pd{
            padding: 0 50px;
            @media only screen and (max-width: 1500px) {
                padding: 0 30px;
            }
            @media only screen and (max-width: 991px) {
                padding: 0;
            }
        }
    }
    .bottom_collumn_desc{
        .image{
            width: 100%;
            height: 0;
            padding-bottom: 56%;
            @include bg_pos;
            background-size: cover;
            @media only screen and (max-width: 991px) {
                margin-top: 30px;
            }
        }
    }
    .middle_desc_collumn, .bottom_collumn_desc{
        .row{
            margin: 0 -22px;
            > div{
                padding: 0 22px;
            }
        }
    }
    .more{
        padding: 119px 0;
        @media only screen and (max-width: 1500px) {
            padding: 60px 0;
        }
        @media only screen and (max-width: 991px) {
            padding:30px 0;
        }
    }
}

section.contact{
    overflow: hidden;
    padding-top: 219px;
    margin-bottom: 145px;
    @media only screen and (max-width: 1500px) {
        padding-top: 170px;
        margin-bottom: 72.5px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 85px;
        margin-bottom: 30px;
    }
    .contact_data{
        background: rgba(16, 39, 34, 0.7);
        padding: 63px 0;
        position: relative;
        margin-bottom: 120px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 60px;
            padding: 43px 0;
        }
        @media only screen and (max-width: 991px) {
            padding: 30px 20px;
            margin-bottom: 30px;
        }
        &:after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 100%;
            background: rgba(16, 39, 34, 0.7);
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        > ul{
            position: relative;
            top: 18px;
            @media only screen and (max-width: 1500px) {
                top: 14px;
            }
            @media only screen and (max-width: 991px) {
                top: 0;
            }
            > li{
                display: inline-block;
                vertical-align: top;
                width: 33%;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: left;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &.adres{
                    p{
                        background-image: url("./../img/footer_contact_icon1.svg");
                        padding-top: 5px;
                    }
                }
                &.phone{
                    a{
                        background-image: url("./../img/phone_icon_small.svg");
                        padding-top: 5px;
                    }
                }
                &.mail{
                    a{
                        background-image: url("./../img/footer_contact_icon2.svg");
                        padding-top: 5px;
                    }
                }
                a,p{
                    min-height: 37px;
                    display: inline-block;
                    text-align: left;
                    font-size: 18px;
                    line-height: 30px;
                    color: #cecece;
                    padding-left: 62px;
                    background-repeat: no-repeat;
                    background-position: left top;
                    @include anim;
                    font-weight: 300;
                    @media only screen and (max-width: 1200px) {
                        font-size: 15px;
                    }
                    @media only screen and (max-width: 991px) {
                        font-size: 13px;
                    }
                    &:hover{
                        text-decoration: none;
                        color: $white;
                    }
                }
            }
        }
    }
    .form_box{
        padding-top: 10%;
        padding-left: 10%;
        @media only screen and (max-width: 1200px) {
            padding-left: 0;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 0;
        }
        .row{
            margin: 0 -40px;
            > div{
                padding: 0 40px;
            }
        }
    }
}
.holder{
    label{
        color: #cecece;
        @include anim;
        font-size: 16px;
        line-height: 53px;
        @include fill;
        &.shrink{
            line-height: 16px;
            top: -16px;
            height: auto;
            font-size: 13px;
            color: $white;
        }
    }
}


.holder{
    position: relative;
    margin-bottom: 30px;
    &.text-right{
        margin-bottom: 0;
        padding-top: 50px;
        @media only screen and (max-width: 1500px) {
            padding-top: 25px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 0;
        }
    }
}

input{
    outline: none;
    &.default_input{
        background:transparent;
        border: none;
        height: 53px;
        width: 100%;
        border-bottom: 1px solid rgba(255,255,255,0.15);
        @include anim;
        color: $white;
        font-size: 16px;
        position: relative;
        z-index: 3;
        &:focus{
            border-bottom: 1px solid rgba(255,255,255,1);
        }
        &.filled{
            border-bottom: 1px solid rgba(255,255,255,1);
        }
    }
    &.default_submit{
        display: inline-block;
        padding-right: 60px;
        background-repeat: no-repeat;
        background-position: right center;
        line-height: 41px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight:700;
        color:$white;
        background-image:url("./../img/more_arr_icon.svg");
        letter-spacing: 2px;
        @include anim;
        border: none;
        background-color: transparent;
        &:hover{
            color:$white;
            text-decoration: none;
            padding-right: 70px;
        }
    }
}

.default_textarea{
    resize: none;
    outline: none;
    background:transparent;
    border: none;
    min-height: 137px;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    @include anim;
    color: $white;
    font-size: 16px;
    padding-top: 15px;
    &:focus{
        border-bottom: 1px solid rgba(255,255,255,1);
    }
    &.filled{
        border-bottom: 1px solid rgba(255,255,255,1);
    }
}

.map{
    padding: 0 77px;
    margin-bottom: 160px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 80px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
        padding: 0 15px;
    }
    .map_holder{
        width: 100%;
        height: 0;
        padding-bottom: 30.3%;
        position: relative;
        @media only screen and (max-width: 991px) {
            padding-bottom: 200px;
        }
        > iframe{
            @include fill;
            border: none;
        }
    }
}

.realizations{
    padding-top: 25px;
    @media only screen and (max-width: 991px) {
        padding-top: 64px;
    }
}

.realizations_list{
    > ul{
        > li{
            margin-bottom: 60px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 35px;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 20px;
            }
            &.desc_left{
                .realization_box{
                    .realization_box_top{
                        @media only screen and (max-width: 991px) {
                            display: flex;
                            flex-flow: column;
                        }
                        > div{
                            @media only screen and (max-width: 991px) {
                                align-items: stretch;
                            }
                            &.desc{
                                //padding-right: 160px;
                                padding-right: 6%;
                                @media only screen and (max-width: 991px) {
                                    order: 2;
                                }
                            }
                        }
                    }
                    .realization_box_bottom{
                        .image{
                            margin-left: auto;
                            width: 70%;
                            height: 0;
                            padding-bottom: 40%;
                            @include bg_pos;
                            background-size: cover;
                            margin-right: 40px;
                        }
                    }
                }
            }
            &.desc_right{
                .realization_box{
                    .realization_box_top{
                        > div{
                            &.desc{
                                //padding-left: 160px;
                                padding-left: 6.37%;
                            }
                        }
                    }
                    .realization_box_bottom{
                        .image{
                            margin-right: auto;
                            width: 70%;
                            height: 0;
                            padding-bottom: 40%;
                            @include bg_pos;
                            background-size: cover;
                            margin-left: 40px;
                        }
                    }
                }
            }
            .realization_box{
                .realization_box_top{
                    > div{
                        width: 50%;
                        display: inline-block;
                        vertical-align: top;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        &.desc{
                            padding-top: 60px;
                            //padding-bottom: 170px;
                            padding-bottom: 8%;
                            @media only screen and (max-width: 1500px) {
                                .section_header{
                                    margin-bottom: 30px;
                                }
                            }
                            @media only screen and (max-width: 991px) {
                                text-align: center;
                                padding-top: 30px;
                                padding-bottom: 30px;
                                .section_header{
                                    margin-bottom: 0;
                                }
                            }
                            .short_desc{
                                margin-bottom: 55px;
                                @media only screen and (max-width: 1500px) {
                                    margin-bottom: 20px;
                                }
                                .default_font{
                                    color: #cecece;
                                }
                            }
                            .end_date{
                                font-size: 14px;
                                line-height: 41px;
                                color: #cecece;
                                text-transform: uppercase;
                                padding-left: 77px;
                                position: relative;
                                @media only screen and (max-width: 991px) {
                                    display: inline-block;
                                    padding-left: 60px;
                                }
                                &:before{
                                    top: 18px;
                                    content:'';
                                    position: absolute;
                                    left: 0;
                                    display: inline-block;
                                    background: rgba(255,255,255,0.15);
                                    width:49px;
                                    height: 1px;
                                }
                            }
                            .adres{
                                color:$font_color2;
                                text-transform: uppercase;
                                padding-left: 25px;
                                background-repeat: no-repeat;
                                background-position: left top;
                                background-image:url('./../img/location.svg');
                                @media only screen and (max-width: 991px) {
                                    display: inline-block;
                                }
                            }
                        }
                        &.image{
                            @include bg_pos;
                            background-size: cover;
                            height: 0;
                            padding-bottom: 31.55%;
                            @media only screen and (max-width: 991px) {
                                padding-bottom: 63%;
                            }
                        }
                    }
                }
                .realization_box_bottom{
                    //margin-top: -116px;
                    margin-top: -6.046%;
                    display: flex;
                    @media only screen and (max-width: 991px) {
                        margin-top: 0;
                        .image_holder{
                            display: none;
                        }
                    }
                    > div{
                        width: 50%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    .more_holder{
                        //margin-top: 116px;
                        margin-top: 6.046%;
                        @include vertical;
                        @media only screen and (max-width: 991px) {
                            margin-top: 0;
                        }
                        > div{
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.features{
    margin-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 35px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
    }
    .features_list{
        padding: 90px 0 130px 0;
        border-bottom:1px solid rgba(255,255,255,0.15);
        text-align: center;
        @media only screen and (max-width: 1500px) {
            padding: 50px 0 65px 0;
        }
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 25%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                div.icon{
                    margin: 0 auto 30px auto;
                    width: 85px;
                    height: 65px;
                    @include bg_pos;
                }
            }
        }
    }
}

.small_gallery{
    padding-left: 53px;
    padding-right: 53px;
    margin-bottom: 180px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 90px;
    }
    @media only screen and (max-width: 991px) {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;
    }
    .small_gallery_holder{
        width: 100%;
        > div{
            display: inline-block;
            vertical-align: bottom;
            &.gallery{
                width: calc(100% - 16% - 24.3%);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                .gal_box{
                    height: 32.45vw;
                    display: flex;
                    @media only screen and (max-width: 991px) {
                        display: block;
                        height: auto;
                    }
                    .image{
                        @include anim;
                        &:hover{
                            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                            filter: grayscale(100%);
                            opacity: 0.5;
                        }
                    }
                    > div{
                        &.left_side{
                            width: 40.16%;
                            margin-right: 30px;
                            position: relative;
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                width: 100%;
                                margin-bottom: 30px;
                            }
                            .image{
                                height: 100%;
                                width: 100%;
                                @include bg_pos;
                                background-size: cover;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    height: 0;
                                    padding-bottom: 149%;
                                }
                            }
                        }
                        &.right_side{
                            width: 61%;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                            .small_boxes{
                                padding-top: 30px;
                                height: calc(100% - 66.4%);
                                display: flex;
                                @media only screen and (max-width: 991px) {
                                    display: block;
                                    height: auto;
                                    margin: 0 -15px;
                                }
                                > div{
                                    width: 50%;
                                    height: 100%;
                                    position: relative;
                                    margin-right: 30px;
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 0;
                                        width: 50%;
                                        display: inline-block;
                                        vertical-align: top;
                                        padding: 0 15px;
                                    }
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    .image{
                                        @include fill;
                                        height: 100%;
                                        width: 100%;
                                        @include bg_pos;
                                        background-size: cover;
                                        @media only screen and (max-width: 991px) {
                                            position: relative;
                                            height: 0;
                                            padding-bottom: 100%;
                                        }
                                    }
                                }
                            }
                            .image{
                                @include bg_pos;
                                background-size: cover;
                                &.medium{
                                    width: 100%;
                                    height: 66.4%;
                                    @media only screen and (max-width: 991px) {
                                        height: 0;
                                        padding-bottom: 66.4%;
                                    }
                                    //margin-bottom: 37px;
                                }
                            }
                        }
                    }
                }
            }
            &.side_image{
                position: relative;
                margin-bottom: 74px;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
                .image{
                    position: absolute;
                    width: 100%;
                    @include bg_pos;
                    background-size: cover;
                }
                &.side_image_left{
                    //width: 295px;
                    //height: 320px;
                    width: 16%;
                    height: 16.5vw;
                    .image{
                        height: 76%;
                        top: 12%;
                    }
                }
                &.side_image_right{
                    width: 24.3%;
                    height: 16.5vw;
                    .image{
                        @include fill;
                    }
                }
            }
        }
    }
}

.main_offer{
    padding-top: 25px;
    @media only screen and (max-width: 1500px) {
        .section_header{
            margin-bottom: 20px;
        }
    }
    @media only screen and (max-width: 991px) {
        padding-top: 85px;
    }
    .offer_list{
        > ul{
            > li{
                &.offer_left{
                    > div{
                        &.desc_side{
                            padding-right: 10%;
                            @media only screen and (max-width: 991px) {
                                padding-right: 0;
                            }
                        }
                    }
                }
                &.offer_right{
                    @media only screen and (max-width: 991px) {
                        display: flex;
                        flex-flow: column;
                    }
                    > div{
                        @media only screen and (max-width: 991px) {
                            align-items: stretch;
                        }
                        &.desc_side{
                            padding-left: 10%;
                            @media only screen and (max-width: 991px) {
                                padding-left: 0;
                                order: 1;
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            &.gal_side{
                                order: 2;
                            }
                        }
                    }
                }
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    width: 50%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    &.desc_side{
                        margin-bottom: 15%;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                            text-align: center;
                        }
                        .short_desc{
                            margin-bottom: 50px;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 30px;
                            }
                            div.default_font{
                                color: $white;
                            }
                        }
                    }
                    &.gal_side{
                        position: relative;
                        height: 100vh;
                        @media only screen and (max-width: 991px) {
                            height: auto;
                        }
                        .gal_img{
                            position: absolute;
                            @media only screen and (max-width: 991px) {
                                position: relative;
                                width: 100% !important;
                                height: 0 !important;
                                padding-bottom: 72% !important;
                                top: 0 !important;
                                left: 0 !important;
                                right: 0 !important;
                                margin-bottom: 30px;
                            }
                            .image{
                                @include fill;
                                @include anim;
                                background-size: cover;
                                &:hover{
                                    transform: scale(1.05);
                                }
                            }
                        }
                        &.gal_type_1{
                            .gal_img1{
                                width: 30.77%;
                                height: 33%;
                                top: 0;
                                right: 8%;
                            }
                            .gal_img2{
                                width: 38.77%;
                                height: 26.6%;
                                top: calc(33% + 30px);
                                right: 0%;
                            }
                            .gal_img3{
                                width: 9.4%;
                                height: 6.1%;
                                top: calc(59.6% + 60px);
                                right: 29.5%;
                            }
                            .gal_img4{
                                width: 57.5%;
                                height: 42%;
                                top: 11%;
                                right: calc(38.77% + 30px);
                            }
                            .gal_img5{
                                width: 19.8%;
                                height: 20.2%;
                                top: calc(42% + 11% + 30px);
                                right: calc(38.77% + 30px);
                            }
                            .gal_img6{
                                width: 19.8%;
                                height: 12.74%;
                                top: calc(42% + 11% + 30px);
                                right: calc(38.77% + 19.8% + 60px);
                            }
                        }
                        &.gal_type_2{
                            .gal_img1{
                                width: 32.3%;
                                height: 20.9%;
                                left: 25%;
                                top: 0;
                            }
                            .gal_img2{
                                width: 57.3%;
                                height: 36.9%;
                                left: 0;
                                top: calc(20.9% + 30px);
                            }
                            .gal_img3{
                                width: 30.3%;
                                height: 16.66%;
                                left: 30px;
                                top: calc(20.9% + 36.9% + 60px);
                            }
                            .gal_img4{
                                width: 20.6%;
                                height: 23.47%;
                                left: calc(30.3% + 60px);
                                top: calc(20.9% + 36.9% + 60px);
                            }
                            .gal_img5{
                                width: 39.6%;
                                height: 32.41%;
                                left: calc(57.3% + 30px);
                                top: 8%;
                            }
                            .gal_img6{
                                width: 31.25%;
                                height: 21.23%;
                                left: calc(57.3% + 30px);
                                top: calc(8% + 32.41% + 30px);
                            }
                            .gal_img7{
                                width: 24.5%;
                                height: 14.77%;
                                left: calc(57.3% + 30px);
                                top: calc(8% + 32.41% + 21.23% + 60px);
                            }
                        }
                        &.gal_type_3{
                            .gal_img1{
                                width: 41.36%;
                                height: 32.3%;
                                top: 0;
                                right: 8%;
                            }
                            .gal_img2{
                                width: 19.8%;
                                height: 32.9%;
                                top: calc(32.3% + 30px);
                                right: 0;
                            }
                            .gal_img3{
                                width: 26.5%;
                                height: 17.55%;
                                top: calc(32.3% + 30px);
                                right: calc(19.8% + 30px);
                            }
                            .gal_img4{
                                width: 24.5%;
                                height: 12%;
                                top: calc(32.3% + 17.55% + 60px);
                                right: calc(19.8% + 30px);
                            }
                            .gal_img5{
                                width: 47.5%;
                                height: 34.2%;
                                top: 19%;
                                right: calc(41.36% + 8% + 30px);
                            }
                            .gal_img6{
                                width: 20%;
                                height: 20%;
                                right: calc(19.8% + 24.5% + 60px);
                                top: calc(34.2% + 19% + 30px);
                            }
                        }
                    }
                }
            }
        }
    }
}

.offer_details{
    padding-top: 75px;
    margin-bottom: 120px;
    @media only screen and (max-width: 1500px) {
        padding-top: 37.5px;
        margin-bottom: 60px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 85px;
        margin-bottom: 0;
    }
    .row{

        &.details_split{
            margin: 0 -60px;
            margin-bottom: 100px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -30px;
                margin-bottom: 50px;
            }
            @media only screen and (max-width: 1200px) {
                margin: 0 -15px;
                margin-bottom: 30px;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
                .desc{
                    margin-bottom: 40px;
                }
            }
            > div{
                padding: 0 60px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 30px;
                }
                @media only screen and (max-width: 1200px) {
                    padding: 0 15px;
                }
            }
        }
    }
    .section_header{
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
        .adres{
            padding-top: 30px;
            @media only screen and (max-width: 991px) {
                padding-top: 20px;
            }
            p{
                font-size: 13px;
                display: inline-block;
                color: #cecece;
                text-transform: uppercase;
                padding-left: 25px;
                background-repeat: no-repeat;
                background-position: left top;
                background-image:url('./../img/location.svg');
            }
        }
    }
    .details_image_holder{
        //padding: 0 77px;
        //margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 991px) {
            padding: 0;
            margin-bottom: 30px;
        }
        .details_image{
            @include bg_pos;
            background-size: cover;
            width: 100%;
            height: 0;
            //padding-bottom: 43.266%;
            padding-bottom: 66%;
        }
    }
    .details_gallery{
        overflow: hidden;
        position: relative;
        > ul{
            position: relative;
            white-space: nowrap;
            margin: 0 -15px;
            > li{
                padding: 0 15px;
                display: inline-block;
                vertical-align: top;
                width: 65%;
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
                @include anim;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &.active{
                    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(0%);
                }
                &.slide1{
                    .gallery{
                        width: 100%;
                        .gal_box{
                            height: 32.45vw;
                            display: flex;
                            @media only screen and (max-width: 991px) {
                                height: auto;
                                display: block;
                            }
                            .image{
                                @include anim;
                                &:hover{
                                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                                    filter: grayscale(100%);
                                    opacity: 0.5;
                                }
                            }
                            > div{
                                &.left_side{
                                    width: 40.16%;
                                    margin-right: 30px;
                                    position: relative;
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 0;
                                        width: 100%;
                                        margin-bottom: 30px;
                                    }
                                    .image{
                                        height: 100%;
                                        width: 100%;
                                        @include bg_pos;
                                        background-size: cover;
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                            height: 0;
                                            padding-bottom: 149%;
                                        }
                                    }
                                }
                                &.right_side{
                                    width: 61%;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                    }
                                    .small_boxes{
                                        padding-top: 30px;
                                        height: calc(100% - 66.4%);
                                        display: flex;
                                        @media only screen and (max-width: 991px) {
                                            display: block;
                                            height: auto;
                                            margin: 0 -15px;
                                        }
                                        > div{
                                            width: 50%;
                                            height: 100%;
                                            position: relative;
                                            margin-right: 30px;
                                            @media only screen and (max-width: 991px) {
                                                margin-right: 0;
                                                width: 50%;
                                                display: inline-block;
                                                vertical-align: top;
                                                padding: 0 15px;
                                            }
                                            &:last-child{
                                                margin-right: 0;
                                            }
                                            .image{
                                                @include fill;
                                                height: 100%;
                                                width: 100%;
                                                @include bg_pos;
                                                background-size: cover;
                                                @media only screen and (max-width: 991px) {
                                                    position: relative;
                                                    height: 0;
                                                    padding-bottom: 100%;
                                                }
                                            }
                                        }
                                    }
                                    .image{
                                        @include bg_pos;
                                        background-size: cover;
                                        &.medium{
                                            width: 100%;
                                            height: 66.4%;
                                            //margin-bottom: 37px;
                                            @media only screen and (max-width: 991px) {
                                                height: 0;
                                                padding-bottom: 66.4%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.slide2{
                    .gallery{
                        width: 100%;
                        .gal_box{
                            height: 32.45vw;
                            display: flex;
                            @media only screen and (max-width: 991px) {
                                height: auto;
                                display: block;
                            }
                            .image{
                                @include anim;
                                &:hover{
                                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                                    filter: grayscale(100%);
                                    opacity: 0.5;
                                }
                            }
                            > div{
                                &.left_side{
                                    width: 40.16%;

                                    position: relative;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                    }
                                    .image{
                                        height: 100%;
                                        width: 100%;
                                        @include bg_pos;
                                        background-size: cover;
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                            height: 0;
                                            padding-bottom: 149%;
                                        }
                                    }
                                }
                                &.right_side{
                                    width: 61%;
                                    margin-right: 30px;
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 0;
                                        width: 100%;
                                        margin-bottom: 30px;
                                    }
                                    .small_boxes{
                                        padding-bottom: 30px;
                                        height: calc(100% - 66.4%);
                                        display: flex;
                                        @media only screen and (max-width: 991px) {
                                            display: block;
                                            height: auto;
                                            margin: 0 -15px;
                                        }
                                        > div{
                                            width: 50%;
                                            height: 100%;
                                            position: relative;
                                            margin-right: 30px;
                                            @media only screen and (max-width: 991px) {
                                                margin-right: 0;
                                                width: 50%;
                                                display: inline-block;
                                                vertical-align: top;
                                                padding: 0 15px;
                                            }
                                            &:last-child{
                                                margin-right: 0;
                                            }
                                            .image{
                                                @include fill;
                                                height: 100%;
                                                width: 100%;
                                                @include bg_pos;
                                                background-size: cover;
                                                @media only screen and (max-width: 991px) {
                                                    position: relative;
                                                    height: 0;
                                                    padding-bottom: 100%;
                                                }
                                            }
                                        }
                                    }
                                    .image{
                                        @include bg_pos;
                                        background-size: cover;
                                        &.medium{
                                            width: 100%;
                                            height: 66.4%;
                                            //margin-bottom: 37px;
                                            @media only screen and (max-width: 991px) {
                                                height: 0;
                                                padding-bottom: 66.4%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .gal_cover{
            position: absolute;
            height: 100%;
            right: 0;
            width: 35%;
            background-repeat: no-repeat;
            background-position: right top;
            background-size: auto 100%;
            background-image: url("./../img/galery_cover.png");
            top:0;
            @include vertical;
            @media only screen and (max-width: 991px) {
                position: relative;
                width: 100%;
                margin: 30px 0;
                background-image: none;
            }
            > div{
                width: 100%;
                padding-right: 77px;
                @media only screen and (max-width: 991px) {
                    padding-right: 0;
                }
                .arr_right{
                    width:67px;
                    height: 0;
                    padding-bottom: 63px;
                    border: 2px solid rgba(255,255,255,0.15);
                    border-radius: 50%;
                    @include bg_pos;
                    background-image: url("./../img/arr_right.svg");
                    cursor: pointer;
                    margin-left: auto;
                    background-size: 35px;
                    @media only screen and (max-width: 991px) {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.toggles{
    margin-bottom: 170px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 85px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    > div{
        width: 50%;
        display: inline-block;
        vertical-align: top;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
        &.toggles_side{
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            ul{
                padding: 73px 0;
                border-left:1px solid rgba(255,255,255,0.2);
                @media only screen and (max-width: 1500px) {
                    padding: 36px 0;
                }
                li{
                    margin-bottom: 85px;
                    cursor: pointer;
                    @media only screen and (max-width: 1500px) {
                        margin-bottom: 42.5px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.t_active{
                        .toggle_header{
                            h2{
                                @include gradient_font;
                            }
                        }
                        .toggle_box{
                            &:before{
                                height: 100%;
                                top: 0;
                            }
                        }
                    }
                }
            }
            .toggle_box{
                padding-left: 10%;
                padding-right: 10%;
                position: relative;
                &:before{
                    content: '';
                    background: rgba(215,153,120,1);
                    background: -moz-linear-gradient(top, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(215,153,120,1)), color-stop(100%, rgba(164,94,66,1)));
                    background: -webkit-linear-gradient(top, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                    background: -o-linear-gradient(top, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                    background: -ms-linear-gradient(top, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                    background: linear-gradient(to bottom, rgba(215,153,120,1) 0%, rgba(164,94,66,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d79978', endColorstr='#a45e42', GradientType=0 );
                    height: 0%;
                    width: 2px;
                    position: absolute;
                    top: 50%;
                    left: -1px;
                    @include anim;
                }
                .toggle_box_top{
                    .toggle_header{
                        font-family: $special_font;
                        h2{
                            text-transform: uppercase;
                            font-size: 36px;
                            line-height: 48px;
                            font-weight: 400;
                            color: $font_color2;
                        }
                    }
                }
                .toggle_box_bottom{
                    .desc{
                        display: none;
                        padding-top: 50px;
                        @media only screen and (max-width: 1500px) {
                            padding-top: 25px;
                        }
                    }
                }
            }
        }
        &.image_side{
            @media only screen and (max-width: 991px) {
                display: none;
            }
            .image_holder{
                .image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 70%;
                    @include bg_pos;
                    background-size: cover;
                    @include anim;
                    visibility: hidden;
                    opacity: 0;
                    &.fadee{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
}


//lp

.page_lp{
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-image: url("./../img/tlo.jpg");

    position: relative;

    overflow: hidden;

    .short_about {
        @media only screen and (max-width: 991px) {
            padding-top: 50px;
        }
        .desc.bottom_desc{
            padding-right: 24%;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
            }
        }
    }
}

.header_lp{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    .menu_btn_holder{
        background: $black;
        width: 76px;
        height: 76px;
        @include vertical;
        cursor: pointer;
        margin-left: auto;
        position: absolute;
        top: 100px;
        right: 15px;
        @media only screen and (max-width: 1700px) {
            top: 50px;
        }
        @media only screen and (max-width: 991px) {
            top: 0;
            right: 0;
        }
        &.menu_open{
            .menu_btn{
                > div{
                    &:nth-child(1){
                        top: 10px;
                        transform: rotate(-45deg);
                    }
                    &:nth-child(2){
                        left: -100%;
                        opacity: 0;
                    }
                    &:nth-child(3){
                        top: 10px;
                        width: 100%;
                        transform: rotate(45deg);
                    }
                }
            }
        }
        .lp_menu{
            position: absolute;
            top: 100%;
            right: 0;
            padding-right: 76px;
            background: $black;
            display: none;
            width: 0;
            nav{
                opacity: 0;
                visibility: hidden;
                padding: 30px 0 30px 30px;
                @include anim;
                white-space: nowrap;
                ul{
                    li{
                        &:hover{
                            div{
                                a{
                                    color: $white;
                                    text-decoration: none;
                                }
                            }
                        }
                        div{
                            a{
                                color: $font_color2;
                                @include anim;
                                font-size: 18px;
                                line-height: 42px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
        &:hover{
            .menu_btn{
                > div{
                    &:nth-child(3){
                        width: 100%;
                    }
                }
            }
        }
        > div{
            width: 100%;
        }
        .menu_btn{
            margin: 0 auto;
            height: 22px;
            width: 40px;
            position: relative;

            > div{
                height: 2px;
                background:$white;
                position: absolute;
                left: 0;
                @include anim;
                &:nth-child(1){
                    top: 0;
                    width: 100%;
                }
                &:nth-child(2){
                    top: 10px;
                    width: 100%;
                }
                &:nth-child(3){
                    top: 20px;
                    width: 80%;
                }
            }
        }
    }
}

.welcome_section{
    display: flex;
    min-height: 100vh;
    width: 100%;
    @media only screen and (max-width: 991px) {
        min-height: 0;
        display: block;
    }
    > div{
        &.desc{
            width: 42%;
            background: $black;
            padding-top: 100px;
            padding-right: 95px;
            @media only screen and (max-width: 1700px) {
                padding-top: 50px;
            }
            @media only screen and (max-width: 1500px) {
                width: 50%;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                min-height: 100vh;
                padding-top: 12px;
            }
            .logo{
                margin-bottom: 85px;
                @media only screen and (max-width: 1700px) {
                    margin-bottom: 50px;
                }
                @media only screen and (max-width: 991px) {
                    img{
                        height: 60px;
                        width: auto;
                        max-width: none;
                    }
                }
            }
            .section_header{
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 30px;
                }
                .adres{
                    color:$font_color2;
                    text-transform: uppercase;
                    padding-left: 25px;
                    background-repeat: no-repeat;
                    background-position: left top;
                    background-image:url('./../img/location.svg');
                    @media only screen and (max-width: 991px) {
                        display: inline-block;
                    }
                }
            }
            .short_desc{
                color: $font_color2;
                font-size: 21px;
                line-height: 41px;
                margin-bottom: 90px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 50px;
                    font-size: 16px;
                    line-height: 31px;
                }
            }
            .to_content_btn{
                display: inline-block;
                font-size: 14px;
                line-height: 41px;
                background-position: right center;
                padding-right: 65px;
                font-weight: 700;
                letter-spacing: 2px;
                background-repeat: no-repeat;
                background-image: url("./../img/scroll_bottom_arr.png");
                color:$white;
                cursor: pointer;
                margin-bottom: 70px;
            }
        }
        &.image_holder{
            width: 58%;
            position: relative;
            overflow: hidden;
            @media only screen and (max-width: 1500px) {
                width: 50%;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                height: 0;
                padding-bottom: 66%;
            }
            .image{
                @include bg_pos;
                @include fill;
                background-size: cover;
            }
        }
    }
}

.page_lp{
    .section_header{
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
    }
}

.location{
    margin-bottom: 160px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 80px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 50px;
    }
    .desc{
        margin-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
    }
    .location_map{
        height: 0;
        position: relative;
        width: 100%;
        padding-bottom: 36%;
        @media only screen and (max-width: 991px) {
            padding-bottom: 200px;
        }
        > iframe{
            @include fill;
            border:none;
        }
    }
}

.gallery_lp{
    margin-bottom: 160px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 80px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 50px;
    }
    .details_gallery{
        overflow: hidden;
        position: relative;
        > ul{
            position: relative;
            white-space: nowrap;
            margin: 0 -15px;
            > li{
                padding: 0 15px;
                display: inline-block;
                vertical-align: top;
                width: 65%;
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
                @include anim;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &.active{
                    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(0%);
                }
                &.slide1{
                    .gallery{
                        width: 100%;
                        .gal_box{
                            height: 32.45vw;
                            display: flex;
                            @media only screen and (max-width: 991px) {
                                height: auto;
                                display: block;
                            }
                            .image{
                                @include anim;
                                &:hover{
                                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                                    filter: grayscale(100%);
                                    opacity: 0.5;
                                }
                            }
                            > div{
                                &.left_side{
                                    width: 40.16%;
                                    margin-right: 30px;
                                    position: relative;
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 0;
                                        width: 100%;
                                        margin-bottom: 30px;
                                    }
                                    .image{
                                        height: 100%;
                                        width: 100%;
                                        @include bg_pos;
                                        background-size: cover;
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                            height: 0;
                                            padding-bottom: 149%;
                                        }
                                    }
                                }
                                &.right_side{
                                    width: 61%;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                    }
                                    .small_boxes{
                                        padding-top: 30px;
                                        height: calc(100% - 66.4%);
                                        display: flex;
                                        @media only screen and (max-width: 991px) {
                                            display: block;
                                            height: auto;
                                            margin: 0 -15px;
                                        }
                                        > div{
                                            width: 50%;
                                            height: 100%;
                                            position: relative;
                                            margin-right: 30px;
                                            @media only screen and (max-width: 991px) {
                                                margin-right: 0;
                                                width: 50%;
                                                display: inline-block;
                                                vertical-align: top;
                                                padding: 0 15px;
                                            }
                                            &:last-child{
                                                margin-right: 0;
                                            }
                                            .image{
                                                @include fill;
                                                height: 100%;
                                                width: 100%;
                                                @include bg_pos;
                                                background-size: cover;
                                                @media only screen and (max-width: 991px) {
                                                    position: relative;
                                                    height: 0;
                                                    padding-bottom: 100%;
                                                }
                                            }
                                        }
                                    }
                                    .image{
                                        @include bg_pos;
                                        background-size: cover;
                                        &.medium{
                                            width: 100%;
                                            height: 66.4%;
                                            //margin-bottom: 37px;
                                            @media only screen and (max-width: 991px) {
                                                height: 0;
                                                padding-bottom: 66.4%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.slide2{
                    .gallery{
                        width: 100%;
                        .gal_box{
                            height: 32.45vw;
                            display: flex;
                            @media only screen and (max-width: 991px) {
                                height: auto;
                                display: block;
                            }
                            .image{
                                @include anim;
                                &:hover{
                                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                                    filter: grayscale(100%);
                                    opacity: 0.5;
                                }
                            }
                            > div{
                                &.left_side{
                                    width: 40.16%;

                                    position: relative;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                    }
                                    .image{
                                        height: 100%;
                                        width: 100%;
                                        @include bg_pos;
                                        background-size: cover;
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                            height: 0;
                                            padding-bottom: 149%;
                                        }
                                    }
                                }
                                &.right_side{
                                    width: 61%;
                                    margin-right: 30px;
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 0;
                                        width: 100%;
                                        margin-bottom: 30px;
                                    }
                                    .small_boxes{
                                        padding-bottom: 30px;
                                        height: calc(100% - 66.4%);
                                        display: flex;
                                        @media only screen and (max-width: 991px) {
                                            display: block;
                                            height: auto;
                                            margin: 0 -15px;
                                        }
                                        > div{
                                            width: 50%;
                                            height: 100%;
                                            position: relative;
                                            margin-right: 30px;
                                            @media only screen and (max-width: 991px) {
                                                margin-right: 0;
                                                width: 50%;
                                                display: inline-block;
                                                vertical-align: top;
                                                padding: 0 15px;
                                            }
                                            &:last-child{
                                                margin-right: 0;
                                            }
                                            .image{
                                                @include fill;
                                                height: 100%;
                                                width: 100%;
                                                @include bg_pos;
                                                background-size: cover;
                                                @media only screen and (max-width: 991px) {
                                                    position: relative;
                                                    height: 0;
                                                    padding-bottom: 100%;
                                                }
                                            }
                                        }
                                    }
                                    .image{
                                        @include bg_pos;
                                        background-size: cover;
                                        &.medium{
                                            width: 100%;
                                            height: 66.4%;
                                            //margin-bottom: 37px;
                                            @media only screen and (max-width: 991px) {
                                                height: 0;
                                                padding-bottom: 66.4%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .gal_cover{
            position: absolute;
            height: 100%;
            right: 0;
            width: 35%;
            background-repeat: no-repeat;
            background-position: right top;
            background-size: auto 100%;
            background-image: url("./../img/galery_cover.png");
            top:0;
            @include vertical;
            @media only screen and (max-width: 991px) {
                position: relative;
                width: 100%;
                margin: 30px 0;
                background-image: none;
            }
            > div{
                width: 100%;
                padding-right: 77px;
                @media only screen and (max-width: 991px) {
                    padding-right: 0;
                }
                .arr_right{
                    width:67px;
                    height: 0;
                    padding-bottom: 63px;
                    border: 2px solid rgba(255,255,255,0.15);
                    border-radius: 50%;
                    @include bg_pos;
                    background-image: url("./../img/arr_right.svg");
                    cursor: pointer;
                    margin-left: auto;
                    background-size: 35px;
                    @media only screen and (max-width: 991px) {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.download{
    .container{
        display: block;
    }
    .download_list{
        padding-bottom: 5%;
        margin-bottom: 10%;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        > ul{
            margin: 0 -30px;
            > li{
                margin-bottom: 40px;
                width: 50%;
                padding: 0 30px;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &:hover{
                    a{
                        text-decoration: none;
                        color: #d79978;
                        span{
                            color:$white;
                        }
                        &:after{
                            left: 0;
                            width: 100%;
                        }
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    display: block;
                    min-height: 32px;
                    color: $white;
                    font-size: 16px;
                    padding-left: 47px;
                    background-repeat: no-repeat;
                    background-position: left top;
                    //background-image: url("./../img/download_icon.png");
                    background-image: url("./../img/d_icon1.png");
                    background-size: auto 30px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    position: relative;
                    @include anim;
                    &:after{
                        @include anim;
                        content: '';
                        width: 0%;
                        left: 50%;
                        position: absolute;
                        bottom: -1px;
                        height: 1px;
                        background: rgba(21,15,12,1);
                        background: -moz-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(21,15,12,1)), color-stop(50%, rgba(199,142,111,1)), color-stop(100%, rgba(21,15,12,1)));
                        background: -webkit-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                        background: -o-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                        background: -ms-linear-gradient(left, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                        background: linear-gradient(to right, rgba(21,15,12,1) 0%, rgba(199,142,111,1) 50%, rgba(21,15,12,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#150f0c', endColorstr='#150f0c', GradientType=1 );
                    }
                    .download_box{
                        margin-bottom: 25px;
                        font-size: 20px;
                        padding-top: 3px;
                        line-height: 30px;
                        padding-right: 47px;
                        background-repeat: no-repeat;
                        background-position: right top;
                        background-image: url("./../img/d_icon2.png");
                        background-size: auto 30px;
                        @media only screen and (max-width: 991px) {
                            font-size: 16px;
                        }
                    }
                    span{
                        color: $font_color2;
                        @include anim;
                    }
                }
            }
        }
    }
}

.contact_lp{
    margin-bottom: 160px;
}

.footer_lp{
    @include vertical;
    height: 175px;
    width: 100%;
    border-top: 1px solid rgba(255,255,255,0.1);
    @media only screen and (max-width: 991px) {
        display: block;
        height: auto;
    }
    > div{
        &.lp_footer_left{
            width: 500px;
            @media only screen and (max-width: 1630px) {
                width: 400px;
            }
            @media only screen and (max-width: 1500px) {
                width: 300px;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding: 30px 0;
                text-align: center;
            }
        }
        &.lp_footer_middle{
            width: calc(100% - (500px + 400px));
            text-align: center;
            @media only screen and (max-width: 1630px) {
                width: calc(100% - (400px + 300px));
            }
            @media only screen and (max-width: 1500px) {
                width: calc(100% - (300px + 300px));
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        &.lp_footer_right{
            width: 400px;
            background: #0a1b16;
            height: 100%;
            @include vertical;
            @media only screen and (max-width: 1630px) {
                width: 300px;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding: 20px 0;
            }
            > div{
                width: 100%;
                text-align: center;
            }
            .to_up{
                letter-spacing: 2px;
                font-weight: 700;
                color:#6c7975;
                font-size:13px;
                line-height: 13px;
                text-transform:uppercase;
                cursor: pointer;
                padding-top: 23px;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: left top;
                background-image: url("./../img/to_up_arr.svg");
                @media only screen and (max-width: 991px) {
                    background-position: center top;
                    margin-bottom: 10px;
                }
            }
        }
    }
    img{
        margin-bottom: 30px;
    }
    font-size: 13px;
    line-height: 23px;
    color: $font_color2;

    .fast_contact_lp{
        > ul{
            @media only screen and (max-width: 1300px) {
                padding-left: 30px;
            }
            @media only screen and (max-width: 991px) {
                padding-left: 0;
            }
            > li{
                text-align: left;
                display: inline-block;
                vertical-align: top;
                padding-left: 57px;
                background-repeat: no-repeat;
                background-position: left top;
                font-size: 14px;
                line-height: 17px;
                min-height: 38px;
                padding-top: 10px;
                margin-right: 100px;
                &:first-child{
                    p{
                        position: relative;
                        top: -8px;
                    }
                }
                @media only screen and (max-width: 1730px) {
                    margin-right: 50px;
                }
                @media only screen and (max-width: 1500px) {
                    margin-right: 30px;

                }
                @media only screen and (max-width: 1300px) {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 10px;
                }
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    padding-left: 0;
                    padding-top: 57px;
                    padding-left: 0;
                    text-align: center;
                    width: 100%;
                    background-position: center top;
                    margin-bottom: 17px;
                }
                &:last-child{
                    margin-right: 0;
                }
                a,p{
                    color: #6c7975;
                }
            }
        }
    }
}

.popup_show_btn{
    position: fixed;
    bottom: 130px;
    right: 0;
    width: 365px;
    height: 100px;
    display: flex;
    cursor: pointer;
    z-index: 99999;
    @include anim;
    margin-right: -265px;
    @media only screen and (max-width: 991px) {
        display: block;
        width: auto;
        margin-right: 0;
        bottom: 0;
        height: auto;
    }
    &:hover{
        margin-right: 0;
    }
    > div{
        &.icon{
            width: 100px;
            background-color:#1a3530;
            @include bg_pos;
            @media only screen and (max-width: 991px) {
                width: 30px;
                height: 30px;
                background-size: 70%;
            }
        }
        &.btn_desc{
            width: calc(100% - 100px);
            background-color: #112622;
            @include vertical;
            @media only screen and (max-width: 991px) {
                display: none;
            }
            > div{
                width: 100%;
                text-align: center;
                font-size: 16px;
                line-height: 23px;
                color: $white;
                p{
                    display: inline-block;
                    padding-right: 65px;
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-image: url("./../img/sub_arr.png");
                }
            }
        }
    }
}

.popup_holder{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999999;
    background: rgba(0,0,0,0.8);
    padding-top: 200px;
    overflow-y: scroll;
    display: none;
    .close_btn{
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        right: 0px;
        cursor: pointer;
        background:#000000;
        text-align: center;
        z-index: 99;
        p{
            @include gradient_font;
        }
    }

    @media only screen and (max-width: 991px) {
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .popup_content{
        position: relative;
        padding: 0 70px 0px 70px;
        @include bg_pos;
        background-size: cover;
        background-image: url("./../img/popup_bg.jpg");
        margin-bottom: 100px;
        width: 870px;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 50px;
            padding: 0 15px;
        }
        .icon{
            width: 100px;
            height: 100px;
            @include bg_pos;
            position: relative;
            top: -50px;
            margin:0px auto 0px auto;
            background-color:#1a3530;
        }
        .popup_header{
            text-align: center;
            margin-bottom: 30px;
            h2{
                font-family: $special_font;
                font-size: 36px;
                line-height: 46px;
                text-transform: uppercase;
                @include gradient_font;
            }
        }
        .inputs{
            margin-bottom: 30px;
        }
    }
    .desc{
        border-top: 1px solid rgba(255,255,255,0.15);
        padding-top: 30px;
        margin-top: 10px;
        font-size: 10px;
        line-height: 19px;
        color: #cecece;
    }
}

.rules{
    > ul{
        > li{
            margin-bottom: 20px;
            > div{
                display: inline-block;
                vertical-align: top;
                &.check_holder{
                    width: 18px;
                    height: 18px;
                }
                &.check_desc{
                    width: calc(100% - 18px);
                    padding-left: 20px;
                    font-size: 10px;
                    line-height: 19px;
                    color: #cecece;
                }
            }
        }
    }
}

.more_rules{
    font-size: 10px;
    line-height: 19px;
    color: #cecece;
    padding-left: 50px;
    @media only screen and (max-width: 991px) {
        padding-left: 0;
    }
    > p{
        margin-bottom: 20px;
    }
    .rules{
        > ul{
            > li{
                margin-bottom: 20px;
                > div {
                    &.check_holder{
                        width: 12px;
                    }
                    &.check_desc{
                        width: calc(100% - 12px);
                    }
                }
                .field_checkbox {
                    .field_checkbox_box {
                        .checkbox_image_box {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }
}


.field_checkbox {
    .field_checkbox_box {
        .checkbox_image_box {
            > div.yes{
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("./../img/check_icon1.png");
                background-size: 12px;
                background-color:transparent;
            }
            > div{
                width: 18px;
                height: 18px;
                border-radius: 0;
                border: 1px solid rgba(255,255,255,0.15);
            }
        }
    }
}


.submit_popup_btn{
    width:240px;
    position: relative;
    background: #091312;
    margin: 0 auto;
    position: relative;
    bottom: -40px;
    text-align: center;
    .popup_submit{
        opacity: 0;
        @include fill;
    }
    span{
        display: inline-block;
        vertical-align: top;
        line-height: 80px;
        padding-right: 65px;
        background-repeat:no-repeat;
        background-position: right center;
        background-image: url("./../img/sub_arr.png");
        color:$white;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 2px;
    }
}

.features {
    .desc{
        .default_font{
            a{
                background: linear-gradient(#d79978, #a45e42);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include anim;
                &:hover{
                    padding-left: 15px;
                }
            }
        }
    }
    .features_list {
        > ul {
            > li{
                &:hover{
                    .icon{
                        transform: translate(0,-10px);
                    }
                    p{
                        color: #d79978;
                        transform: translate(0,10px);
                    }
                }
                .icon{
                    @include anim;
                }
                p{
                    @include anim;
                }
            }
        }
    }
}

.details_gallery_new{
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    &.simple_gallery_list{
        > div{
            &.carousel_content{
                width: 100%;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    ul{
                        white-space: normal;
                        li{
                            margin-bottom: 30px;
                            .image{
                                padding-bottom: 66%;
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            &.carousel_nav{
                display: none;
            }
        }
    }
    > div{
        display: inline-block;
        vertical-align: middle;
        &.carousel_content{
            width: calc(100% - 130px);
        }
        &.carousel_nav{
            width: 130px;
            .arr_btn_right{
                margin-left: auto;
                cursor: pointer;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                border: 2px solid rgba(255,255,255,0.3);
                @include bg_pos;
                background-image: url("./../img/arr_right.svg");
            }
        }
    }
    .carousel_content{
        overflow: hidden;
        > ul{
            position: relative;
            white-space: nowrap;
            margin: 0 -15px;
            @media only screen and (max-width: 991px) {
                margin: 0;
            }
            > li{
                display: inline-block;
                vertical-align: top;
                padding: 0 15px;
                width: 25%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding: 0;
                    margin-right: 10%;
                }
            }
        }
    }
    .image{
        @include bg_pos;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-bottom: 86%;
    }
}
